html{
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  padding: 0;
  padding-bottom: 0px;
  /* background: url(/img/mario-bg.png) no-repeat; 
  
  background-color: #95e8f3;*/
  background-size: cover;
  background-position: center;
  min-height: 100%;


  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #f5f8fa;

}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

.red-text {
  color: rgba(255, 0, 0, 0.849);
}

.btn-group-xs > .btn, .btn-xs {
  margin-bottom: 0px;
  margin-top: 0px;
  padding: .18rem .4rem;
  font-size: .65rem;
  line-height: 0;
  border-radius: 1.5rem;
}

.black{
  background-color: rgb(0, 0, 0);
}

/*Login*/

.my-form .row
{
    margin-left: 0;
    margin-right: 0;
}

.login-form .row
{
    margin-left: 0;
    margin-right: 0;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between
}

.flex-wrapper-form {
  display: flex;
  min-height: 72vh;
  flex-direction: column;
}

.background-rotate {
  background-size:cover;
  background-image: url("/background.gif");
}

.mydiv {
  background-size:cover;
  color:black;
  animation: myanimation 50s infinite;
}

@keyframes myanimation {
  
  0% {  
    background-image: url("https://i.imgur.com/8jwoYQu.jpg");
    background-position: bottom;
  }
  25%{  
    background-image: url("/background2.jpg");
    background-position: bottom;
  }
  50%{  
    background-image: url("/background3.jpg");
    background-position: bottom;
  }
  75%{  
    background-image: url("/background4.jpg");
    background-position: bottom;
  }
  100% {  
    background-image: url("/background5.jpg");
    background-position: bottom;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form{
  padding: 20px;
  margin-top: 3px;
}

form button, form h5{
  margin: 20px 0;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #ec407a;
  box-shadow: none;
}

input[type=text]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label{
  color: #ec407a !important;
} 

/*Form Error*/

.error {
  color:#db2222;
  font-size: 0.725em;
  display: relative;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.logo {
  height: 50px;
  width: auto;
}

/*card*/
.card {
  font-size: 1em;
  overflow: hidden;
  padding: 0;
  border: none;
  border-radius: .28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.card-block {
  font-size: 1em;
  position: relative;
  margin: 0;
  padding: 1em;
  border: none;
  /*border-top: 1px solid rgba(34, 36, 38, .1);*/
  box-shadow: none;
}

.card-border-red{
  box-shadow: 0 1px 5px 0 #c00000, 0 0 0 1px #d4d4d5;
}
.card-border-green{
  box-shadow: 0 1px 5px 0 #047c00, 0 0 0 1px #d4d4d5;
}

.card-img-top {
  display: block;
  width: 175px;
  height: 225px;
}

.card-title {
  font-size: 1.28571429em;
  font-weight: 700;
  line-height: 1.2857em;
}

.card-text {
  clear: both;
  margin-top: .5em;
  color: rgba(0, 0, 0, .68);
}

.card-footer {
  font-size: 1em;
  position: static;
  vertical-align: bottom;
  top: 0;
  left: 0;
  max-width: 100%;
  padding: .75em 1em;
  color: rgba(0, 0, 0, .4);
  border-top: 1px solid rgba(0, 0, 0, .05) !important;
  background: #fff;
}

.card-inverse .btn {
  border: 1px solid rgba(0, 0, 0, .05);
}

.text-bold {
  font-weight: 700;
}

.meta {
  font-size: 1em;
  color: rgba(0, 0, 0, .4);
}

.meta a {
  text-decoration: none;
  color: rgba(0, 0, 0, .4);
}

.meta a:hover {
  color: rgba(0, 0, 0, .87);
}


