.well {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05)
}

.well blockquote {
	border-color: #ddd;
	border-color: rgba(0, 0, 0, 0.15)
}

.well-lg {
	padding: 24px;
	border-radius: 6px
}

.well-sm {
	padding: 9px;
	border-radius: 3px
}



.sidemenu {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none
}

.sidemenu:before,
.sidemenu:after {
	display: table;
	content: " "
}

.sidemenu:after {
	clear: both
}

.sidemenu:before,
.sidemenu:after {
	display: table;
	content: " "
}

.sidemenu:after {
	clear: both
}

.sidemenu>li {
	position: relative;
	display: block
}

.sidemenu>li>button {
	position: relative;
	display: block;
	padding: 10px 15px
}

.sidemenu>li>button:hover,
.sidemenu>li>button:focus {
	text-decoration: none;
	background-color: #eee
}

.sidemenu>li.disabled>button {
	color: #999
}

.sidemenu>li.disabled>button:hover,
.sidemenu>li.disabled>button:focus {
	color: #999;
	text-decoration: none;
	cursor: not-allowed;
	background-color: transparent
}

.sidemenu .open>button,
.sidemenu .open>button:hover,
.sidemenu .open>button:focus {
	background-color: #eee;
	border-color: #428bca
}

.sidemenu .sidemenu-divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5
}

.sidemenu>li>button>img {
	max-width: none
}

.sidemenu-tabs {
	border-bottom: 1px solid #ddd
}

.sidemenu-tabs>li {
	float: left;
	margin-bottom: -1px
}

.sidemenu-tabs>li>button {
	margin-right: 2px;
	line-height: 1.428571429;
	border: 1px solid transparent;
	border-radius: 4px 4px 0 0
}

.sidemenu-tabs>li>button:hover {
	border-color: #eee #eee #ddd
}

.sidemenu-tabs>li.active>button,
.sidemenu-tabs>li.active>button:hover,
.sidemenu-tabs>li.active>button:focus {
	color: #555;
	cursor: default;
	background-color: #fff;
	border: 1px solid #ddd;
	border-bottom-color: transparent
}

.sidemenu-tabs.sidemenu-justified {
	width: 100%;
	border-bottom: 0
}

.sidemenu-tabs.sidemenu-justified>li {
	float: none
}

.sidemenu-tabs.sidemenu-justified>li>button {
	text-align: center
}

@media(min-width:768px) {
	.sidemenu-tabs.sidemenu-justified>li {
		display: table-cell;
		width: 1%
	}
}

.sidemenu-tabs.sidemenu-justified>li>button {
	margin-right: 0;
	border-bottom: 1px solid #ddd
}

.sidemenu-tabs.sidemenu-justified>.active>button {
	border-bottom-color: #fff
}

.sidemenu-pills>li {
	float: left
}

.sidemenu-pills>li>button {
	border-radius: 5px
}

.sidemenu-pills>li+li {
	margin-left: 2px
}

.sidemenu-pills>li.active>button,
.sidemenu-pills>li.active>button:hover,
.sidemenu-pills>li.active>button:focus {
	color: #fff;
	background-color: #428bca
}

.sidemenu-stacked>li {
  float: none;
  margin-left: 10px

}

.sidemenu-stacked>li+li {
	margin-top: 2px;
	margin-left: 10px
}

.sidemenu-justified {
	width: 100%
}

.sidemenu-justified>li {
	float: none
}

.sidemenu-justified>li>button {
	text-align: center
}

@media(min-width:768px) {
	.sidemenu-justified>li {
		display: table-cell;
		width: 1%
	}
}

.sidemenu-tabs-justified {
	border-bottom: 0
}

.sidemenu-tabs-justified>li>button {
	margin-right: 0;
	border-bottom: 1px solid #ddd
}

.sidemenu-tabs-justified>.active>button {
	border-bottom-color: #fff
}