a:hover{
    text-decoration:none;
}
.section-padding {
    padding: 10px 0;
}
.bottom {
    background-color: #414141;
}
.bottom .copyright {
    color: #e5e5e5;
    font-weight: 600;
}

.bottom p {
    margin-bottom: 0;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
}
.copyright p {
    color: #868686;
}

.problems p {
    color: #adadad;
}

.problems p span {
    color: #f5f5f5;
}

.links p {
    color: #adadad;
}

.qlink {
    color: #adadad;
    text-decoration: underline;
}
.qlink:hover{
    color: #ffffff;
    text-decoration: underline;
}